import WasmController from "react-lib/frameworks/WasmController";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// APIs
import EncounterPatientListOptimized from "issara-sdk/apis/EncounterPatientListOptimized_core";
import CentralLabTestFilterResultList from "issara-sdk/apis/CentralLabTestFilterResultList_apps_LAB";
import CentralLabResultComparable from "issara-sdk/apis/CentralLabResultComparable_apps_LAB";
import CentralLabOrderApproveList from "issara-sdk/apis/CentralLabOrderApproveList_apps_LAB";
import CentralLabDetailList from "issara-sdk/apis/CentralLabDetailList_apps_LAB";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import CentralLabSummaryFileResultListList from "issara-sdk/apis/CentralLabSummaryFileResultListList_apps_LAB";

// Serializer
import CentralLabDetailSerializer from "issara-sdk/types/CentralLabDetailSerializer_apps_LAB";
import DoctorSerializer from "issara-sdk/types/DoctorSerializer_core";

// Interface
import { State as MainState } from "HIS/MainHISInterface";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";

import FormLabExamSummary from "../FormLabExamSummary";

export type State = Partial<{
  // sequence
  OPDLabSummarySequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    encounterList: Record<string, any>[];
    encounterId: number | null;
    labResultList: Record<string, any>[];
    fileResultList: Record<string, any>[];
    allLabResultList: Record<string, any>[];
    groupLabResult: Record<string, any>[];
    divisionList: Record<string, any>[];
    divisionId: number[] | string;
    labName: string | number[];
    tableColumns: string[];
    fromEncounterId: number | null;
    labDetailList: CentralLabDetailSerializer[];
    doctorDetail: DoctorSerializer;
    chatDetail: any;
    doctorRecommends?: Record<string, string>;
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "selectedPatient"
    | "selectedEncounter"
    | "errorMessage"
    | "chatDetail"
    | "buttonLoadCheck"
    | "permissionLabResultConfidential"
  >
>;

// Sequence
type SeqState = {
  sequence: "OPDLabSummary";
  restart?: boolean;
  clear?: boolean;
  byDate?: boolean;
  card?: string;
};
// Handle Action
type ActionType =
  | { action: "CHANGE"; name: string; value: any }
  | { action: "SEARCH"; fillLabName?: boolean; byDate: boolean; card?: string }
  | {
      action: "APPROVE";
      card: string;
      groupByParentLab: any;
      approved: boolean;
    }
  | { action: "GET_LAB_DETAIL_LIST"; byDate?: boolean; groupByParentLab: any }
  | {
      action: "DOWNLOAD_PDF";
      groupByParentLab: any;
      parentLabID: any;
      doctorRecommends?: Record<string, string>;
    }
  | {
      action: "SEND_PDF";
      groupByParentLab: any;
      doctorRecommends?: Record<string, string>;
      parentLabID: any;
      btnKey: string;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  OPDLabSummarySequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | {
      message: "postChatChannelMessage";
      params: {
        apiToken: string;
        content?: string;
        contentType: "file" | "text";
        chatChannelId: number;
        contentFile?: File;
        divisionId?: number;
      };
    };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params: SeqState) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["labDivision", {}]],
    },
  } as any);

  const encounter = await EncounterPatientListOptimized.get({
    apiToken: controller.apiToken,
    params: {
      exclude_canceled: true,
      patient: state.selectedPatient?.id,
      encounter_type: params.byDate ? undefined : "OPD",
    },
  });

  let items: any[] = encounter[0]?.items || [];
  const encounterId = state.selectedEncounter?.id;

  items = items.filter(
    (item) =>
      !!item.doctor_orders.find(
        (acc: any) => acc.specific_type === "centrallaborder"
      )
  );

  const encounterDetail = items.find((item) => item.id === encounterId);
  // #const toDate = encounterDetail?.created.split(" ")?.[0];

  // #let labResult = { lab: [], columns: [], files: [] };

  // Cumulative ไม่ต้องส่ง encounter, OPD Lab ต้องส่ง Encounter
  // if (params.byDate || (!params.byDate && encounterDetail?.id)) {
  //   labResult = await GetCentralLabResultList(controller, {
  //     patient: state.selectedPatient?.id,
  //     encounter: params.byDate ? undefined : encounterDetail?.id,
  //     to_date: params.byDate ? "" : toDate,
  //     from_date: "",
  //     after_collected: true,
  //   });
  // }

  controller.setState(
    {
      OPDLabSummarySequence: {
        sequenceIndex: "Action",
        encounterList: items,
        // Cumulative ไม่ต้อง default encounter
        encounterId: params.byDate ? null : encounterDetail?.id,
        // labResultList: labResult.lab,
        // allLabResultList: labResult.lab,
        // groupLabResult: getGroupLabResult(controller, {
        //   lab: labResult.lab,
        //   files: labResult.files,
        //   encounter: encounterDetail,
        // }),
        divisionId: "all",
        labName: "all",
        // tableColumns: labResult.columns,
        // fileResultList: labResult.files,
      },
      permissionLabResultConfidential: false,
    },
    () =>
      controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params: ActionType) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "CHANGE") {
    HandleChange(controller, params);
  } else if (params.action === "APPROVE") {
    HandleApprove(controller, params);
  } else if (params.action === "GET_LAB_DETAIL_LIST") {
    HandleGetCentralLabDetailList(controller, params);
  } else if (params.action === "DOWNLOAD_PDF") {
    HandleDownLoadPDF(controller, params);
  } else if (params.action === "SEND_PDF") {
    HandleSendPDF(controller, params);
  }
};

const HandleSearch: Handler = async (controller, params: Params<"SEARCH">) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const data = state.OPDLabSummarySequence || {};

  // * From หรือ Date (Encounter) หรือ First encounter
  const fromEnId =
    data.fromEncounterId ||
    data.encounterId ||
    "CURRENT";

  const toEnId = data.encounterId || data.encounterList?.[0]?.id;

  const toDate = getDateFormEncounter(fromEnId, data.encounterList);
  const fromDate = getDateFormEncounter(toEnId, data.encounterList);
  const labDetail = await GetCentralLabDetailList(controller, {
    encounterId: data.encounterId,
  });

  let labResult = { lab: [], columns: [], files: [] };
  let allLabResultList = data.allLabResultList || [];
  let fileResultList = data.fileResultList || [];

  if (!params.fillLabName) {
    // Cumulative ไม่ต้องส่ง encounter, OPD Lab ต้องส่ง Encounter
    if (params.byDate || (!params.byDate && data.encounterId)) {
      labResult = await GetCentralLabResultList(controller, {
        patient: state.selectedPatient?.id,
        encounter: params.byDate ? undefined : data.encounterId,
        // division: data?.divisionId,
        // to_date: toDate,
        from_date: fromDate,
        after_collected: true,
      });
    }

    allLabResultList = labResult.lab;
    fileResultList = labResult.files;
  } else {
    const isAll =
      (Array.isArray(data.divisionId) && !data.divisionId.length) ||
      data.divisionId === "all";

    data.divisionId = isAll
      ? "all"
      : Array.isArray(data.divisionId)
      ? data.divisionId
      : [Number(data.divisionId)];

    labResult = await GetCentralLabResultComparable(controller, {
      items: allLabResultList,
      division: data.divisionId,
      patient: state.selectedPatient?.id,
      encounter: params.byDate ? undefined : data?.encounterId,
      // to_date: toDate,
      from_date: fromDate,
    });
  }

  const result = getGroupLabResult(controller, {
    lab: labResult.lab,
    files: fileResultList,
  });

  if (params.fillLabName) {
    data.labName =
      data.divisionId === "all"
        ? "all"
        : result
            .map((item: any) => (item.parent ? item.code : null))
            .filter(Boolean);
  }

  controller.setState({
    OPDLabSummarySequence: {
      ...data,
      labResultList: labResult.lab,
      allLabResultList,
      groupLabResult: result,
      tableColumns: labResult.columns,
      fileResultList,
      labDetailList: labDetail[0]?.items || [],
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleChange: Handler = (controller, params: Params<"CHANGE">) => {
  const state = controller.getState();

  const data: any = state.OPDLabSummarySequence || {};

  data[params.name] = params.value;

  if (params.name === "encounterId") {
    data.divisionId = "all";
    data.labName = "all";
  }

  controller.setState(
    {
      OPDLabSummarySequence: {
        ...data,
      },
    },
    () => {
      if (
        ["encounterId", "divisionId", "fromEncounterId"].includes(params.name)
      ) {
        HandleSearch(controller, {
          ...params,
          fillLabName: params.name === "divisionId",
          action: "SEARCH",
        });
      }
    }
  );
};

const HandleApprove: Handler = async (
  controller,
  params: Params<"APPROVE">
) => {
  const state = controller.getState();

  const doctorRecommends = state.OPDLabSummarySequence?.doctorRecommends || {};
  const recommendations = Object.entries(params.groupByParentLab || {}).map(
    ([key, value]: any) => ({
      lab_id: value.id,
      recommendation: doctorRecommends[value.id],
    })
  );

  const result = await CentralLabOrderApproveList.post({
    apiToken: controller.apiToken,
    data: {
      encounter: state.OPDLabSummarySequence?.encounterId,
      approved: params.approved,
      recommendations,
    },
  });

  if (result[1]) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: result[1] },
    });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: null },
    });

    HandleGetCentralLabDetailList(controller, params);
  }
};

const HandleGetCentralLabDetailList: Handler = async (
  controller,
  params: Params<"GET_LAB_DETAIL_LIST">
) => {
  const state = controller.getState();

  const data = state.OPDLabSummarySequence || {};

  const allLabResultList = data.allLabResultList || [];
  const toDate = getDateFormEncounter(data.encounterId, data.encounterList);
  const fromDate = getDateFormEncounter(
    data.fromEncounterId,
    data.encounterList
  );

  const result = await GetCentralLabDetailList(controller, {
    encounterId: data.encounterId,
  });

  const getDoctorDetail = DoctorDetail.retrieve({
    apiToken: controller.apiToken,
    pk: result[0]?.items?.[0]?.approved_by,
  });

  const getLabResult = GetCentralLabResultComparable(controller, {
    items: allLabResultList,
    division: data.divisionId,
    patient: state.selectedPatient?.id,
    encounter: params.byDate ? undefined : data?.encounterId,
    to_date: toDate,
    from_date: fromDate,
  });

  const [doctor, labResult] = await Promise.all([
    getDoctorDetail,
    getLabResult,
  ]);

  const recommendations = Object.entries(params.groupByParentLab || {}).map(
    ([key, value]: any) => ({
      [value.id]:
        labResult.lab.find((item: any) => item.id === value.id)?.lab_comparable
          ?.recommendation || "",
    })
  );

  controller.setState({
    OPDLabSummarySequence: {
      ...state.OPDLabSummarySequence,
      labDetailList: result[0]?.items || [],
      doctorDetail: doctor[0] || {},
      chatDetail: state.chatDetail,
      doctorRecommends: Object.assign({}, ...recommendations),
    },
  });
};

export const HandleDownLoadPDF: Handler = async (
  controller,
  params: Params<"DOWNLOAD_PDF">
) => {
  const pdfMake = await createPDF(controller, params);

  pdfMake.open();
};

export const HandleSendPDF: Handler = async (
  controller,
  params: Params<"SEND_PDF">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "LOADING",
    },
  });

  const pdfMake = await createPDF(controller, params);

  const dataURL = await new Promise<string>((resolve, reject) =>
    pdfMake.getDataUrl((dataUrl: string) => resolve(dataUrl))
  );

  const file = dataURLtoFile(dataURL, "report.pdf");

  await controller.handleEvent({
    message: "postChatChannelMessage",
    params: {
      apiToken: controller.apiToken,
      contentType: "file",
      chatChannelId: state.chatDetail?.chat_channel,
      contentFile: file,
      divisionId: controller.data.division,
    },
  });

  await controller.handleEvent({
    message: "postChatChannelMessage",
    params: {
      apiToken: controller.apiToken,
      content:
        "รายงานผลตรวจทางห้องปฏิบัติการ หากมีข้อสงสัยสามารถพิมพ์สอบถามได้เลยนะคะ",
      chatChannelId: state.chatDetail?.chat_channel,
      contentType: "text",
      divisionId: controller.data.division,
    },
  });

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "SUCCESS",
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetCentralLabResultList: Handler = async (controller, params) => {
  const [labFilter, fileResult] = await Promise.all([
    CentralLabTestFilterResultList.list({
      apiToken: controller.apiToken,
      params: {
        patient: params.patient,
        encounter: params.encounter,
        after_collected: params.after_collected,
        // encounter: 1290,
        lab_division: Number(params.division) || undefined,
      },
    }),
    CentralLabSummaryFileResultListList.get({
      apiToken: controller.apiToken,
      params: {
        patient: params.patient,
        encounter: params.encounter,
        start_order_date: params.from_date,
        end_order_date: params.to_date,
      },
    }),
  ]);

  const items: any[] = labFilter[0]?.items || [];
  const response = await GetCentralLabResultComparable(controller, {
    ...params,
    items,
  });

  return { ...response, files: fileResult[0]?.items || [] };
};

const GetCentralLabResultComparable: Handler = async (controller, params) => {
  let items: any[] = params.items;
  const division: any[] = params.division;

  if (Array.isArray(division)) {
    items = items.filter((item) => division.includes(item.lab_division));
  }

  const productIds = items.flatMap((item) => [
    item.product_id,
    ...item.children.map((acc: any) => acc.product_id),
  ]);

  const labResult = await CentralLabResultComparable.post({
    apiToken: controller.apiToken,
    data: {
      columns: [],
      items: [],
      patient: params.patient,
      products: productIds,
      to_date: params.to_date,
      from_date: params.from_date,
    },
  });

  let columns = labResult[0]?.columns || [];

  const mergeLab = items.map((item) => {
    const lab = (labResult[0]?.items || []).find(
      (acc: any) => acc.lab_id === Number(item.product_id)
    );

    if (lab) {
      const formateDateLab = (obj: any) => {
        const dates = Object.keys(obj).filter((acc) => acc.search(" ") >= 0);

        for (const date of dates) {
          //* 15/12/2566 09:15:37 to 15/12/2566 [09:15]
          const formattedDate = date.replace(/(\d{2}:\d{2}):(\d{2})$/, "[$1]");

          obj[formattedDate] = obj[date];

          delete obj[date];
        }
      };

      formateDateLab(lab);

      // * หากมี Lab ลูก ให้ format ด้วย
      if (lab.children?.length) {
        for (const [index] of lab.children.entries()) {
          formateDateLab(lab.children[index]);
        }
      }
    }

    return {
      ...item,
      lab_comparable: lab || null,
    };
  });

  columns = columns.map((column: any) =>
    column.replace(/(\d{2}:\d{2}):(\d{2})$/, "[$1]")
  );

  return {
    lab: mergeLab,
    // * เอาเวลาที่ซ้ำกันออก
    columns: [...new Set(columns)],
  };
};

const GetCentralLabDetailList: Handler = async (controller, params) => {
  return CentralLabDetailList.list({
    apiToken: controller.apiToken,
    params: {
      encounter: params.encounterId,
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const dateFormat = "DD/MM/YYYY HH:mm";

const getGroupLabResult: Handler = (controller, params): any[] => {
  const state = controller.getState();

  const data = state.OPDLabSummarySequence || {};

  const encounter =
    data.encounterList?.find((item) => item.id === data.encounterId) ||
    params.encounter;

  const lab = params.lab as Record<string, any>[];
  const files = params.files as Record<string, any>[];

  // Group by lab_division_name
  const groupLab = (lab || []).reduce((result, item) => {
    const name = item.lab_division_name;
    if (name in result) {
      result[name].items.push(item);
    } else {
      result[name] = {
        code: name,
        lab_code: item.lab_code,
        lab_division: item.lab_division,
        items: [item],
      };
    }
    return result;
  }, {});

  const items = Object.values(groupLab);

  const sortedItems = items
    .sort((a, b) => a.code.localeCompare(b.code))
    .map((item) => ({
      ...item,
      items: item.items.sort((a, b) => a.code.localeCompare(b.code)),
    }));

  return sortedItems.flatMap((item) => [
    { code: item.code, header: true, lab_division: item.lab_division },
    ...item.items.flatMap((acc: any) => [
      {
        id: acc.id,
        code: acc.lab_code,
        name: acc.name,
        is_confidential: acc.is_confidential,
        recommendation: acc.recommendation,
        parent: true,
        parent_code: acc.lab_code,
        recommendation_high: acc.recommendation_high,
        recommendation_low: acc.recommendation_low,
        recommendation_normal: acc.recommendation_normal,
        lab_division: item.lab_division,
        ...((acc.children || []).length
          ? { toggle: true }
          : {
              unit: acc.lab_comparable?.lab_unit || "",
              ...getResult({
                data: acc.lab_comparable,
                criticalValue: {
                  min: acc.critical_value_min,
                  max: acc.critical_value_max,
                },
                refValue: acc.lab_comparable?.ref_value,
                encounter,
                genderName: state.selectedPatient?.gender_name,
                files,
              }),
            }),
      },
      ...(acc.children || []).map((obj: any) => {
        const find = acc.lab_comparable.children?.find((child: any) => child.lab_id === obj.id);

        return {
          id: obj.id,
          code: obj.lab_code,
          name: obj.name,
          is_confidential: obj.is_confidential,
          recommendation: obj.recommendation,
          unit: find?.lab_unit || "",
          parent_code: acc.lab_code,
          recommendation_high: acc.recommendation_high,
          recommendation_low: acc.recommendation_low,
          recommendation_normal: acc.recommendation_normal,
          lab_division: item.lab_division,
          ...getResult({
            data: find,
            criticalValue: {
              min: acc.critical_value_min,
              max: acc.critical_value_max,
            },
            refValue: find?.ref_value,
            encounter,
            genderName: state.selectedPatient?.gender_name,
            files,
          }),
        };
      }),
    ]),
  ]);
};

// Pattern
const FLOAT_REGEX = "[+-]?\\d{1,3}(?:,\\d{3})*(\\.\\d+|\\d*)";
const FLOAT_NONE_REGEX = `${FLOAT_REGEX}|None`;
const BETWEEN_REGEX = new RegExp(
  `^(${FLOAT_NONE_REGEX}) *- *(${FLOAT_REGEX})$`,
  "g"
);
const GTE_REGEX = new RegExp(`^(>=?) *(${FLOAT_REGEX})+$`, "g");
const LTE_REGEX = new RegExp(`^(<=?) *(${FLOAT_REGEX})+$`, "g");
const GENDER_REGEX = new RegExp(
  `(Female|Male): *([<|>]=?) *(${FLOAT_REGEX})`,
  "g"
);
// #const GENDER_REGEX_2 = /(Female|Male): *(\d+(\.\d+)? *- *\d+(\.\d+)?)*/g;
const GENDER_BETWEEN_REGEX = new RegExp(
  `(Female|Male): *(${FLOAT_NONE_REGEX}) *- *(${FLOAT_REGEX})`,
  "g"
);

const formatDateForm = (date: string) => {
  return moment(date, dateFormat).format("YYYY/MM/DD HH:mm");
};

export const getRefValue = (refValue: string, genderName: string) => {
  // let secondsMatches = Array.from(refValue?.matchAll(GENDER_REGEX_2) || []);

  // Female: >=50 ,Male: >=40
  if (!!refValue?.match(GENDER_REGEX)?.length) {
    let matches = Array.from(refValue.matchAll(GENDER_REGEX));
    return matches.find((item) => item?.[1] === genderName)?.[0] || refValue;
  }
  // Female: 2.4 - 5.7 ,Male: 3.4 - 7.0
  else if (!!refValue?.match(GENDER_BETWEEN_REGEX)?.length) {
    let matches = Array.from(refValue.matchAll(GENDER_BETWEEN_REGEX));
    return matches.find((item) => item?.[1] === genderName)?.[0] || refValue;
  } else {
    return refValue;
  }
};

export const getNormalRange = (refValue: string, genderName: string) => {
  const parseNormalRange = (rangeString: string) => {
    const ranges = rangeString.split(",");
    const genderRange = ranges.find((range) => range.includes(genderName));
    if (genderRange) {
      const [_, normalRange] = genderRange.split(":");
      return normalRange.trim();
    }
    return refValue;
  };
  const normalRange = parseNormalRange(refValue);
  return normalRange;
};

const getBetweenStatus = (data: {
  number: number;
  min: string;
  max: string;
}) => {
  return data.number < (Number(data.min) || 0)
    ? "L"
    : data.number > Number(data.max)
    ? "H"
    : "";
};

const getBetweenClinical = (data: {
  number: number;
  status: string;
  criticalValue: Record<"max" | "min", number | null>;
}) => {
  return data.status
    ? data.criticalValue.max !== null && data.number >= data.criticalValue.max
      ? true
      : data.criticalValue.min !== null && data.number <= data.criticalValue.min
      ? true
      : false
    : false;
};

const parseNumber = (value?: string) => Number((value || "").replaceAll(",", ""));

export const getStatus = (params: {
  refValue: string;
  criticalValue: Record<"max" | "min", number | null>;
  value: string;
  genderName: string;
}) => {
  const number = parseNumber(params.value);

  console.log("params.refValue", params.refValue);

  // let secondsMatches = Array.from(
  //   params.refValue?.matchAll(GENDER_REGEX_2) || []
  // );

  if (params.value && !Number.isNaN(number)) {
    // Female: >=50 ,Male: >=40
    if (!!params.refValue?.match(GENDER_REGEX)?.length) {
      const matches = Array.from(params.refValue.matchAll(GENDER_REGEX));
      const type: Record<string, string> = {};

      for (const match of matches) {
        const status = match[2].includes("<") ? "H" : "L";
        type[match[1]] = eval(`${number}${match[2]}${parseNumber(match[3])}`) ? "" : status;
      }

      const status = type[params.genderName || ""] || "";
      const match = matches.find((match) => match[1] === params.genderName);

      return {
        status,
        is_critical: false,
        range: match?.[3] ? [parseNumber(match?.[3])] : [],
        refValue: match?.[0] || params.refValue,
      };
    }
    // Female: 2.4 - 5.7 ,Male: 3.4 - 7.0
    else if (!!params.refValue?.match(GENDER_BETWEEN_REGEX)?.length) {
      const matches = Array.from(params.refValue.matchAll(GENDER_BETWEEN_REGEX));
      const type: Record<string, string> = {};

      for (const match of matches) {
        type[match[1]] = getBetweenStatus({
          number,
          min: parseNumber(match[2]),
          max: parseNumber(match[4]),
        });
      }

      const status = type[params.genderName || ""] || "";
      const match =
        matches.find((match) => match[1] === params.genderName) || [];

      return {
        status,
        is_critical: getBetweenClinical({
          number,
          criticalValue: params.criticalValue,
          status,
        }),
        range: [parseNumber(match[2]), parseNumber(match[4])],
        refValue: params.refValue,
      };
    }
    //  else if (secondsMatches.length > 0) {
    //   let refValue = secondsMatches.find(
    //     (item) => item?.[1] === params.genderName
    //   )?.[0];
    //   let result = secondsMatches.find(
    //     (item) => item?.[1] === params.genderName
    //   )?.[2];
    //   // result = "2.0-5.0"
    //   let hlResult = result?.split("-");
    //   let status: string = "";
    //   if (hlResult) {
    //     status =
    //       number < (Number(hlResult[0]) || 0)
    //         ? "L"
    //         : number > Number(hlResult[1])
    //         ? "H"
    //         : "";
    //   }

    //   return {
    //     status,
    //     is_critical: false,
    //     range: result ? [Number(result)] : [],
    //     refValue: refValue || params.refValue,
    //   };
    // }
    // 10 - 20
    else if (!!params.refValue?.match(BETWEEN_REGEX)?.length) {
      const match = BETWEEN_REGEX.exec(params.refValue) || [];
      const status = getBetweenStatus({
        number,
        min: parseNumber(match[1]),
        max: parseNumber(match[3]),
      });
      const isCritical = getBetweenClinical({
        number,
        criticalValue: params.criticalValue,
        status,
      });

      return {
        status,
        is_critical: isCritical,
        range: [parseNumber(match[1]) || 0, parseNumber(match[3])],
        refValue: params.refValue,
      };
    }
    // <=10
    else if (!!params.refValue?.match(LTE_REGEX)?.length) {
      const match = LTE_REGEX.exec(params.refValue) || [];
      const status = eval(`${number}${match[1]}${parseNumber(match[2])}`) ? "" : "H";
      const isCritical =
        !!status &&
        params.criticalValue.max !== null &&
        number >= params.criticalValue.max;

      return {
        status,
        is_critical: isCritical,
        range: [parseNumber(match[2])],
        refValue: params.refValue,
      };
    }
    // >20
    else if (!!params.refValue?.match(GTE_REGEX)?.length) {
      const match = GTE_REGEX.exec(params.refValue) || [];
      const status = eval(`${number}${match[1]}${parseNumber(match[2])}`) ? "" : "L";
      const isCritical =
        !!status &&
        params.criticalValue.min !== null &&
        number <= params.criticalValue.min;

      return {
        status,
        is_critical: isCritical,
        range: [parseNumber(match[2])],
        refValue: params.refValue,
      };
    }
  }

  return {
    status: "",
    is_critical: false,
    range: [],
    refValue: params.refValue,
  };
};

const getResult = (params: {
  data: Record<string, any>;
  criticalValue: Record<"max" | "min", number | null>;
  refValue: string;
  encounter?: Record<string, any>;
  genderName: string;
  files: any[];
}) => {
  const { data, criticalValue, refValue, encounter, genderName } = params;
  const currentDate = encounter?.created.split(" ")?.[0] || "";

  // Key ที่เป็นวันที่
  const dates = Object.entries(data || {}).filter((item) =>
    moment(item[0], dateFormat).isValid()
  );
  // Sort วันที่
  const sort = dates.sort((a, b) =>
    Intl.Collator().compare(formatDateForm(b[0]), formatDateForm(a[0]))
  );
  // files
  const fileResult = params.files.flatMap((item) =>
    item.specimen_datetime
      ? [
          {
            ...item,
            date: `${formatDate(moment(item.specimen_datetime))} [${moment(
              item.specimen_datetime
            ).format("HH:mm")}]`,
          },
        ]
      : []
  );

  const index = sort.findIndex((item) => item[0].includes(currentDate));
  const prev =
    index !== -1
      ? sort[index + 1]
      : sort.find(
          (item) =>
            formatDateForm(item[0]) <= formatDateForm(`${currentDate} [23:59]`)
        );
  const prevResult = prev?.[1] || "";
  const prevDate = prev?.[0] || "";

  const result = sort[index]?.[1] || "";
  const resultDateTime = sort[index]?.[0] || "";

  const resultStatus = getStatus({
    refValue,
    criticalValue,
    value: result,
    genderName,
  });
  const prevStatus = getStatus({
    refValue,
    criticalValue,
    value: prevResult,
    genderName,
  });

  // get file result by date
  const getFileResult = (date: string, prefix: string) => {
    const item = fileResult.find(
      (item) => item.date === date && data.name === item.lab_test_name
    );

    return {
      [`${prefix}file`]: !!item,
      [`${prefix}order_item_id`]: item?.order_item_id || null,
    };
  };

  return {
    result,
    result_status: resultStatus.status,
    result_critical: resultStatus.is_critical,
    result_date_time: resultDateTime,
    ...getFileResult(resultDateTime, "result_"),
    prev_result: prevResult,
    prev_result_status: prevStatus.status,
    prev_result_critical: prevStatus.is_critical,
    prev_date: prevDate?.split(" ")?.[0] || "",
    prev_date_time: prevDate,
    ...getFileResult(prevDate, "prev_"),
    lab_id: params.data?.lab_id,
    ref_value: getRefValue(refValue, genderName),
    dates: sort.map((item) => {
      const status = getStatus({
        refValue,
        criticalValue,
        value: item[1],
        genderName,
      });

      return {
        key: item[0],
        value: item[1],
        status: status.status,
        lab_id: params.data?.lab_id,
        critical: status.is_critical,
        ...getFileResult(item[0], ""),
      };
    }),
  };
};

const createPDF: Handler = async (controller, params) => {
  const state = controller.getState();
  const patient = state?.selectedPatient;

  const data = Object.assign({
    time: moment().format("HH:mm") || "",
    date: formatDate(moment()) || "",
    patient: patient || {},
    parentLabID: params.parentLabID || [],
    labResultList: params.groupByParentLab || [],
    doctorRecommends: params.doctorRecommends,
    doctorDetail: state.OPDLabSummarySequence?.doctorDetail || {},
  });

  let docDef: any = { content: [] };

  docDef = await FormLabExamSummary(data);

  return (await getPdfMake()).createPdf(docDef);
};

const dataURLtoFile = (dataUrl: string, filename: string) => {
  var arr: any = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const getDateFormEncounter = (
  encounter?: number | null | "CURRENT",
  encounterList?: any[]
) => {
  if (encounter === "CURRENT") {
    return formatDate(moment());
  }

  const date = encounterList?.find(
    (item) => item.id === encounter || null
  )?.created_utc;

  return date ? formatDate(moment(date)) : "";
};
