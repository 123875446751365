import WasmController from "react-lib/frameworks/WasmController";

import axios from "axios";
import CONFIG from "config/config";
import moment from "moment";

// APIs
import CentralLabResultCreateList from "issara-sdk/apis/CentralLabResultCreateList_apps_LAB";
import CentralLabResultByOrderGroupbyTestList from "issara-sdk/apis/CentralLabResultByOrderGroupbyTestList_apps_LAB";
import CentralLabSummaryFileResultList from "issara-sdk/apis/CentralLabSummaryFileResultList_apps_LAB";
import CentralLabSummaryFileResultListList from "issara-sdk/apis/CentralLabSummaryFileResultListList_apps_LAB";
import CentralLabSummaryFileResultDetail from "issara-sdk/apis/CentralLabSummaryFileResultDetail_apps_LAB";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { HandleGetEmployeeTokenization } from "react-lib/apps/HISV3/TPD/TPDInterface";

// Form
import FormLabExamSummary from "../FormLabExamSummary";
import FormLaboratoryTestResult from "../FormLaboratoryTestResult";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";

export type State = Partial<{
  LabReportSequence: Partial<{
    sequenceIndex: string | null;
    selectedStartDate: string | null;
    selectedEndDate: string | null;
    selectedLabDivision: string | null;
    selectedLabTest: string | null;
    centralLabResult: {
      id?: number | null;
      items?: any[];
    };
    LabReportToken: {
      token?: string;
      employeeName?: string;
      error?: any;
      loading?: boolean;
      code?: string;
      password?: string;
    };
    LabReportList: any[];
    selectedLabReport: any;
  }> | null;
  selectedLabOrderWorking: any;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "selectedPatient"
    | "selectedEncounter"
    | "errorMessage"
    | "buttonLoadCheck"
    | "successMessage"
    | "labOrderQueue"
  >
>;

// Sequence
type SeqState = {
  sequence: "LabReport";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | {
      action: "attach_file";
      card: string;
      buttonLoadKey: string;
      upload: { result: any }[];
      remove: number[];
      date: string;
      time: string;
      labOrderItems: any[];
      password: string;
    }
  | {
      action: "EDIT" | "AUTHORIZE";
      card: string;
      buttonLoadKey: string;
      labOrderItems: any[];
      username: string;
      password: string;
      // callback
      onUpdated?: () => any;
    }
  | { action: "token"; code: string }
  | { action: "FETCH_LAB_SUMMARY_FILE_RESULT"; btnKey: string; params: any }
  | { action: "select_report"; data: any }
  | { action: "PRINT_LAB_REPORT"; data: any }
  | {
      action: "PRINT_LAB_RESULT";
      card: string;
      data: any;
      selectedLabOrder: any;
      selectedPatient: any;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  LabReportSequence: {
    sequenceIndex: null,
    selectedStartDate: null,
    selectedEndDate: null,
    selectedLabDivision: null,
    selectedLabTest: "",
    centralLabResult: {},
    LabReportToken: {},
    LabReportList: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterLabDivisions?: any[];
  userProfile?: Record<string, any>
};

export const DataInitial = {
  masterLabDivisions: [],
};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["labDivision", {}]],
    },
  } as any);

  const state = controller.getState();

  const centralLab = await GetCentralLabResultByOrderGroupbyTest(controller, {
    id: state.selectedLabOrderWorking?.id,
  });

  controller.setState({
    LabReportSequence: {
      ...state.LabReportSequence,
      LabReportToken: {},
      sequenceIndex: "Action",
      centralLabResult: centralLab?.[0] || {},
    },
  });
};

export const Action: Handler = async (controller, params: ActionType) => {
  const state = controller.getState();

  if (params.action === "token") {
    const [response, error, network] = await HandleGetEmployeeTokenization(controller as any, {
      code: params.code,
    });

    if (error) {
      controller.setState({
        LabReportSequence: {
          ...state.LabReportSequence,
          LabReportToken: { error: error, loading: false },
        },
      });
      return;
    }
    controller.setState({
      LabReportSequence: {
        ...state.LabReportSequence,
        LabReportToken: {
          token: response?.token || "",
          employeeName: decodeURIComponent(atob(response?.token.split(".")[1])) || "",
          loading: false,
          code: params.code,
        },
      },
    });
  } else if (params.action === "attach_file") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const employeeCode = await GetEmployeeCode(controller, {
      ...params,
      btnKey: params.buttonLoadKey,
      cardKey: params.card,
      isError: true,
      // password: state.LabReportSequence?.LabReportToken?.password
    });

    if (!employeeCode) {
      return;
    }

    const filterItems = params.labOrderItems
      .flatMap((item: any) => (item.children.length ? item.children : [item]))
      .filter((item: any) => item.chk)
      .map((item) => item.central_lab_order_item_id);

    let error = null;

    for (const item of params.upload) {
      const fileResult = await CentralLabSummaryFileResultList.create({
        data: {
          order: state.LabReportSequence?.centralLabResult?.id,
          ref_order_items: filterItems,
          description: "",
          report_pdf: item.result.replace(/^data:application\/pdf;base64,/g, ""),
          employee_code: employeeCode,
          report_datetime: `${params.date}-${params.time}`,
        } as any,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      if (fileResult[1]) {
        error = fileResult[1];
        break;
      }
    }

    if (!error) {
      // หากมี file ที่ต้องการ delete
      const promiseArr = params.remove.map((id) =>
        CentralLabSummaryFileResultDetail.delete({
          pk: id,
          apiToken: controller.apiToken,
          extra: { division: controller.data.division },
        })
      );

      const response = await Promise.all(promiseArr);

      if (response.some((item) => item[1])) {
        error = response.map((item) => item[1]);
      }
    }

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: error },
        },
      });
    } else {
      controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params?.card]: "บันทึกสำเร็จ",
          },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },

          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          LabReportSequence: {
            sequenceIndex: "START",
            centralLabResult: state.LabReportSequence?.centralLabResult,
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "LabReport" as any,
            },
          })
      );
    }
  } else if (params.action === "AUTHORIZE" || params.action === "EDIT") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const flatMapItems = (items?: any[]) =>
      (items || []).flatMap((item: any) => (item.children.length ? item.children : [item]));

    const rawLabOrderItems = flatMapItems(state.LabReportSequence?.centralLabResult?.items);

    let labOrderItems = flatMapItems(params.labOrderItems);

    labOrderItems = labOrderItems
      .filter((item: any, index) =>
        params.action === "EDIT"
          ? item.central_lab_result_value !== null &&
            item.central_lab_result_value !== rawLabOrderItems[index].central_lab_result_value
          : item.chk
      )
      .map((item: any) => ({
        id: item.central_lab_result_id,
        order_item: item.central_lab_order_item_id,
        value: item.central_lab_result_value,
        comment: {},
      }));

    let saveLabResult: any = [{}, null, null];

    const { userId } = await controller.handleEvent({
      message: "CheckUserCredentials",
      params: {
        ...params,
        btnKey: params.buttonLoadKey,
        cardKey: params.card,
        isError: true,
        password: params.password,
      },
    });

    if (!userId) {
      return;
    }

    if (labOrderItems.length > 0) {
      saveLabResult = await CentralLabResultCreateList.post({
        data: {
          items: labOrderItems,
          action: params.action,
          username: controller.data.userProfile?.username,
          password: params?.password,
        },
        apiToken: controller.apiToken,
      });
    }

    if (saveLabResult[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: saveLabResult[1] },
        },
      });
    } else {
      params.onUpdated?.();

      controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params.card]: saveLabResult[0],
          },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          LabReportSequence: {
            sequenceIndex: "START",
            centralLabResult: state.LabReportSequence?.centralLabResult,
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "LabReport" as any,
            },
          })
      );
    }
  } else if (params.action === "FETCH_LAB_SUMMARY_FILE_RESULT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.btnKey]: "LOADING",
      },
    });

    const [response, error, network] = await CentralLabSummaryFileResultListList.get({
      apiToken: controller.apiToken,
      params: {
        ...params.params,
        start_order_date: state.LabReportSequence?.selectedStartDate,
        end_order_date: state.LabReportSequence?.selectedEndDate,
        lab_division:
          state.LabReportSequence?.selectedLabDivision === "all"
            ? ""
            : state.LabReportSequence?.selectedLabDivision,
        lab_test:
          state.LabReportSequence?.selectedLabTest === "all"
            ? ""
            : state.LabReportSequence?.selectedLabTest,
      },
    });
    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.btnKey]: "ERROR",
        },
        LabReportSequence: { ...state.LabReportSequence, LabReportList: [] },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.btnKey]: "SUCCESS",
        },
        LabReportSequence: {
          ...state.LabReportSequence,
          LabReportList: response?.items || [],
        },
      });
    }
  } else if (params.action === "select_report") {
    controller.setState({
      LabReportSequence: {
        ...state.LabReportSequence,
        selectedLabReport: params.data
          ? { ...params.data, summary_file: params.data.summary_file }
          : null,
      },
    });
  } else if (params.action === "PRINT_LAB_REPORT") {
    const state = controller.getState();
    const patient = state?.selectedPatient;

    const data = {
      time: moment().format("HH:mm") || "",
      date: formatDate(moment()) || "",
      patient: patient || {},
      parentLabID: params.data.map((item: any) => item.code),
      labResultList: getGroupByParentLab(params.data),
      hideRecommendation: true,
    };

    const docDef: any = await FormLabExamSummary(data);

    (await getPdfMake()).createPdf(docDef).open();
  } else if (params.action === "PRINT_LAB_RESULT") {
    const btnKey = `${params.card}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    HandlePrintLabResult(controller, params);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
    });
  }
};

export const HandlePrintLabResult: Handler = async (controller, params) => {
  const state = controller.getState();

  const patient = params.selectedPatient;
  const list = state.labOrderQueue?.labOrderQueueList || [];
  const { id, arrive_time: arriveTime, date } = params.selectedLabOrder || {};

  const orderByName = list.find((item) => item.id === id)?.order_by_name || "";

  const data = {
    arriveTime,
    // labResultList: getGroupByParentLab(params.data),
    labResultList: params.data,
    parentLabID: params.data.map((item: any) => item.code),
    patient: patient || {},
    receivedDatetime: arriveTime,
    requestedBy: orderByName,
    requestedDatetime: date,
  };

  const docDef: any = await FormLaboratoryTestResult(data);

  (await getPdfMake()).createPdf(docDef).open();
};

// Utils
const getGroupByParentLab = (lists: any[]) => {
  const group = lists.reduce((result, item) => {
    const mapDates = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        dates: [{ value: item.value, status: item.result_status }],
      }));
    };
    const children = item.children?.length
      ? [item, ...mapDates(item.children)]
      : mapDates([item]);

    result[item.code] = {
      id: item.id,
      items: children.map((acc: any) => ({
        authorize_user_fullname: acc.central_lab_result_authorize_user_fullname,
        dates: acc.dates,
        name: acc.name,
        ref_value: acc.ref_value_txt,
        report_user_fullname: acc.central_lab_result_report_user_fullname,
        unit: acc.unit,
      })),
    };

    return result;
  }, {} as any);

  return lists.length ? group : { default: { id: null, items: [] } };
};

// APIs
const GetEmployeeCode: Handler = async (controller, params) => {
  const { userId } = await controller.handleEvent({ message: "CheckUserCredentials", params });

  if (!userId) {
    return null;
  }

  const [employee] = await UserEmployeeDetailAPIView.retrieve({
    apiToken: controller.apiToken,
    pk: userId,
    extra: { division: controller.data.division },
  });

  return (employee?.code || "") as string;
};

export const GetCentralLabResultByOrderGroupbyTest: Handler = (controller, params) =>
  CentralLabResultByOrderGroupbyTestList.get({
    apiToken: controller.apiToken,
    params: {
      ab_type: "OUTLAB",
      after_collected: true,
      order_id: params.id,
    },
  }) as Promise<[unknown, unknown]>;